// React와 관련 라이브러리 임포트
import React, { useState, useEffect } from 'react'; // React의 주요 기능과 상태 관리, 사이드 이펙트 관리 기능 임포트
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // 라우팅(페이지 전환)을 위한 라이브러리

// 컴포넌트와 페이지 파일 임포트
import Navigation from './components/Navigation'; // 네비게이션 바 컴포넌트
import BottomNavigation from './components/BottomNavigation'; // 하단 네비게이션 바 컴포넌트
import MainPage from './pages/MainPage'; // 메인 페이지
import SportsLearning from './pages/SportsLearning'; // 스포츠 학습 페이지
import SportsInput from './pages/SportsInput'; // 스포츠 데이터 입력 페이지
import TravelProducts from './pages/TravelProducts'; // 여행 상품 목록 페이지
import TravelProductsInput from './pages/TravelProductsInput'; // 여행 상품 입력 페이지
import TravelProductsDetail from './pages/TravelProductsDetail'; // 여행 상품 상세 페이지
import EquipmentPurchase from './pages/EquipmentPurchase'; // 장비 구매 페이지
import EquipmentPurchaseInput from './pages/EquipmentPurchaseInput'; // 장비 구매 입력 페이지
import ParticipantDetail from './pages/ParticipantDetail'; // 참가자 상세 페이지
import ApplyHistory from './User/ApplyHistory'; // 사용자 신청 내역 페이지
import ScheduleDetail from './pages/ScheduleDetail'; // 스케줄 상세 페이지
import SignupPage from './pages/SignupPage'; // 회원가입 페이지
import PaymentResult from './pages/PaymentResult'; // 결제 결과 페이지
import UserProfile from './pages/UserProfile'; // 사용자 프로필 페이지
import LoginModal from './components/LoginModal'; // 로그인 모달 창
//개발 모달
import UnderConstructionModal from './pages/UnderConstruction';

// 외부 라이브러리 스타일 임포트
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap 스타일
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome 아이콘
import "./App.css"; // 프로젝트에서 정의한 스타일

// 앱 컴포넌트 정의: 프로젝트의 시작점
function App() {
  // 상태 관리: 로그인 모달, 사용자 ID, 사용자 레벨
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // 로그인 모달 열림 여부를 관리
  const [userId, setUserId] = useState(''); // 현재 로그인한 사용자 ID
  const [level, setLevel] = useState(''); // 현재 로그인한 사용자의 레벨(권한)

  // 컴포넌트가 처음 로드될 때 실행되는 코드 (초기 데이터 로드)
  useEffect(() => {
    // 로컬 저장소(localStorage)에서 저장된 사용자 정보 불러오기
    const storedUserId = localStorage.getItem('userId'); // 사용자 ID 가져오기
    const storedLevel = localStorage.getItem('level'); // 사용자 레벨 가져오기
    if (storedUserId) {
      setUserId(storedUserId); // 사용자 ID 상태 업데이트
    }
    if (storedLevel) {
      setLevel(storedLevel); // 사용자 레벨 상태 업데이트
    }
  }, []); // 빈 배열([])은 이 코드가 처음 한 번만 실행되도록 설정

  // 로그인 버튼 클릭 시 실행되는 함수
  const handleLoginClick = () => {
    setIsLoginModalOpen(true); // 로그인 모달을 열기
  };

  // 로그인 모달 닫기 함수
  const closeLoginModal = () => {
    setIsLoginModalOpen(false); // 로그인 모달 닫기
  };

  // 사용자 ID 업데이트 함수
  const updateUserId = (newUserId) => {
    setUserId(newUserId); // 사용자 ID 상태 업데이트
    localStorage.setItem('userId', newUserId); // 로컬 저장소에 저장
  };

  // 사용자 레벨 업데이트 함수
  const updateLevel = (newLevel) => {
    setLevel(newLevel); // 사용자 레벨 상태 업데이트
    localStorage.setItem('level', newLevel); // 로컬 저장소에 저장
  };
  //개발 페이지 모달
  const [isModalOpen, setIsModalOpen] = useState(false); 

  useEffect(() => {
    setIsModalOpen(true); // 페이지가 로드되면 모달을 자동으로 띄움
  }, []);

  // 컴포넌트의 화면 렌더링
  return (
    <Router> {/* 라우터 설정 시작: URL 경로에 따라 다른 컴포넌트를 렌더링 */}
      <div className="App">
        {/* 개발 모달 */}
        <UnderConstructionModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <header className="App-header"> {/* 화면 상단의 네비게이션 바 */}
          <Navigation 
            onLoginClick={handleLoginClick} // 로그인 버튼 클릭 시 실행
            userId={userId} // 현재 사용자 ID를 네비게이션에 전달
            level={level} // 현재 사용자 레벨을 네비게이션에 전달
          />
        </header>
        <div className="App-body"> {/* 메인 콘텐츠 영역 */}
          <Routes> {/* URL 경로별로 어떤 페이지를 보여줄지 정의 */}
            <Route path="/" element={<MainPage />} /> {/* 메인 페이지 */}
            <Route path="/sports-learning" element={<SportsLearning />} /> {/* 스포츠 학습 페이지 */}
            <Route path="/input" element={<SportsInput />} /> {/* 스포츠 데이터 입력 페이지 */}
            <Route path="/travel_product" element={<TravelProducts />} /> {/* 여행 상품 목록 페이지 */}
            <Route path="/travel_products_input" element={<TravelProductsInput setUserId={updateUserId} />} /> {/* 여행 상품 입력 페이지 */}
            <Route path="/travel-product-detail" element={<TravelProductsDetail setUserId={updateUserId} />} /> {/* 여행 상품 상세 페이지 */}
            <Route path="/equipment_purchase" element={<EquipmentPurchase />} /> {/* 장비 구매 페이지 */}
            <Route path="/equipment_purchase_input" element={<EquipmentPurchaseInput />} /> {/* 장비 구매 입력 페이지 */}
            <Route path="/apply_history" element={<ApplyHistory />} /> {/* 사용자 신청 내역 페이지 */}
            <Route path="/schedule/:id" element={<ScheduleDetail setUserId={updateUserId} />} /> {/* 스케줄 상세 페이지 (URL 파라미터 :id) */}
            <Route path="/signup" element={<SignupPage userId={userId} setUserId={updateUserId} />} /> {/* 회원가입 페이지 */}
            <Route path="/payment-result" element={<PaymentResult setUserId={updateUserId} />} /> {/* 결제 결과 페이지 */}
            <Route path="/user-profile" element={<UserProfile />} /> {/* 사용자 프로필 페이지 */}
            <Route path="/participant-detail" element={<ParticipantDetail />} /> {/* 참가자 상세 페이지 */}
          </Routes>
        </div>
        {/* 로그인 모달 컴포넌트 */}
        <LoginModal
          isOpen={isLoginModalOpen} // 모달의 열림 상태
          onClose={closeLoginModal} // 모달 닫기 함수
          setUserId={updateUserId} // 로그인 성공 시 사용자 ID 업데이트 함수
          setLevel={updateLevel}  // 로그인 성공 시 사용자 레벨 업데이트 함수
          />  
        {/* <BottomEvent /> */}
        <BottomNavigation />
      </div>
    </Router>
  );
}

// App 컴포넌트를 외부에서 사용할 수 있도록 내보냄
export default App;
