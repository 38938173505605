// React와 react-router-dom 라이브러리에서 필요한 기능을 임포트
import React from 'react'; // React 컴포넌트 작성에 필요한 라이브러리
import { Link } from 'react-router-dom'; // 페이지 이동을 위한 Link 컴포넌트
import './Navigation.css'; // 네비게이션 관련 CSS 파일 임포트

// Navigation 컴포넌트 정의
// Props로 onLoginClick(로그인 버튼 클릭 함수), userId(사용자 ID), level(사용자 레벨)을 받음
function Navigation({ onLoginClick, userId, level }) {
  return (
    <nav className="navigation"> {/* 네비게이션 전체를 감싸는 태그 */}
      <div className="nav-container"> {/* 네비게이션 내부 컨테이너 */}
        
        {/* 로고를 클릭하면 메인 페이지로 이동 */}
        <Link to="/" className="nav-logo"> 
          {/* 로고 이미지 표시 (PUBLIC_URL은 public 폴더 기준 경로) */}
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="DEEPS" />
        </Link>
        
        {/* "클래스" 메뉴: 클릭하면 "/sports-learning" 페이지로 이동 */}
        {/* <Link to="/sports-learning" className="nav-link-item"> */}
          {/* 메뉴 아이콘 */}
          {/* <img src={`${process.env.PUBLIC_URL}/images/class.svg`} alt="클래스" className="nav-icon" /> */}
          {/* 메뉴 이름 */}
          {/* <span>클래스</span>
        </Link> */}
        
        {/* "여행" 메뉴: 클릭하면 "/travel_product" 페이지로 이동 */}
        {/* <Link to="/travel_product" className="nav-link-item"> */}
          {/* 메뉴 아이콘 */}
          {/* <img src={`${process.env.PUBLIC_URL}/images/travel_ico.svg`} alt="여행" className="nav-icon" /> */}
          {/* 메뉴 이름 */}
          {/* <span>여행</span>
        </Link> */}
        
        {/* "쇼핑" 메뉴: 클릭하면 "/shopping" 페이지로 이동 */}
        {/* <Link to="/shopping" className="nav-link-item"> */}
          {/* 메뉴 아이콘 */}
          {/* <img src={`${process.env.PUBLIC_URL}/images/shopping_ico.svg`} alt="쇼핑" className="nav-icon" /> */}
          {/* 메뉴 이름 */}
          {/* <span>쇼핑</span>
        </Link> */}

        {/* 사용자 정보 표시: 로그인 여부에 따라 다르게 렌더링 */}
        {userId ? (
          // 로그인 상태: 사용자 프로필 페이지 링크와 정보 표시
          <Link to="/user-profile" className="nav-user">
            <div className="user-icon-level">
              {/* 사용자 레벨 표시 */}
              <span className="user-level">LV. {level}</span>
            </div>
            {/* 사용자 이름 표시 */}
            <span className="user-name">{userId}</span>
          </Link>
        ) : (
          // 비로그인 상태: 로그인 버튼 표시
          <button className="nav-login" onClick={onLoginClick}>
            {/* 로그인 버튼 텍스트 */}
            <span>로그인</span>
          </button>
        )}
      </div>
    </nav>
  );
}

export default Navigation; // Navigation 컴포넌트를 외부에서 사용할 수 있도록 내보냄
